var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('h3',{staticClass:"heading-3-b mb-20"},[_vm._v("회사법인정보")]),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"group column vertical-form condensed"},[_c('div',{staticClass:"group row"},[_vm._m(0),_c('div',{staticClass:"form-value"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(this.$store.getters.getOrderTempCompanyInfo.corporateName))])])]),_c('div',{staticClass:"group row"},[_vm._m(1),_c('div',{staticClass:"form-value"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(this.$store.getters.getOrderTempCompanyInfo.businessLicenseNum))])])]),(this.$store.getters.getOrderTempCompanyInfo.ceoName)?_c('div',{staticClass:"group row"},[_vm._m(2),_c('div',{staticClass:"form-value"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(this.$store.getters.getOrderTempCompanyInfo.ceoName))])])]):_vm._e(),(this.$store.getters.getOrderTempCompanyInfo.companyAddress)?_c('div',{staticClass:"group row"},[_vm._m(3),_c('div',{staticClass:"form-value"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(this.$store.getters.getOrderTempCompanyInfo.companyAddress))])])]):_vm._e(),(this.$store.getters.getOrderTempCompanyInfo.businessCondition)?_c('div',{staticClass:"group row"},[_vm._m(4),_c('div',{staticClass:"form-value"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(this.$store.getters.getOrderTempCompanyInfo.businessCondition))])])]):_vm._e(),(this.$store.getters.getOrderTempCompanyInfo.businessType)?_c('div',{staticClass:"group row"},[_vm._m(5),_c('div',{staticClass:"form-value"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(this.$store.getters.getOrderTempCompanyInfo.businessType))])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('span',{staticClass:"txt"},[_vm._v("상호명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('span',{staticClass:"txt"},[_vm._v("사업자등록번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('span',{staticClass:"txt"},[_vm._v("대표자명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('span',{staticClass:"txt"},[_vm._v("주소")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('span',{staticClass:"txt"},[_vm._v("업태")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('span',{staticClass:"txt"},[_vm._v("종목")])])
}]

export { render, staticRenderFns }