<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">담당자 정보</h3>
		<div class="contents">
			<div class="group column vertical-form condensed">
				<div class="group row">
					<label class="form-label"><span class="txt">담당자명</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.name }}</span>
					</div>
				</div>
				<div class="group row" v-if="this.$store.getters.getAllCompanyInfo.companyManagerInfo.grade">
					<label class="form-label"><span class="txt">직급</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.grade }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">연락처(휴대폰)</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.phone }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">연락처(유선번호)</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.tel }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">이메일</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.email }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">세금계산서 담당자명</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.issuedBillName }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">세금계산서 이메일</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.issuedBillEmail }}</span>
					</div>
				</div>
				<div class="group row">
					<div class="form-value">* 주문담당자 연락처와 이메일로 주문 완료 알림이 발송됩니다.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
