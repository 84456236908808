<template>
	<Fragment>
		<div class="container">
			<div class="body-contents payment">
				<h2 class="heading-1-b">신규 주문</h2>
				<ol class="stepper type2">
					<li class="step on">
						<a href="#" title="step01">
							<i>1</i>
							<span>서비스 및 상품 선택</span>
						</a>
					</li>
					<li class="step on">
						<a href="#" title="step02">
							<i>2</i>
							<span>약관 동의 및 결제</span>
						</a>
					</li>
					<li class="step on">
						<a href="#" title="step03">
							<i>3</i>
							<span>주문 완료</span>
						</a>
					</li>
				</ol>
				<div class="section-wrap gap64">
					<div class="section border-bottom">
						<div class="contents text-center">
							<!-- 신규 주문과 문구 다름  -->
							<p class="text-large">다우오피스 멤버십 결제가 완료되었습니다.</p>
							<img class="guide" src="@/assets/img/dada.png" />
							<p class="text-medium mb-16">
								구매하신 서비스 및 상품이 적용되기까지 <em class="primary">약 10분~20분</em> 정도 소요됩니다.<br />
								구매 상품이 적용되지 않았을 경우 스피키 챗봇 상담 혹은<br />
								영업담당자에게 연락 주시기 바랍니다.<br />
							</p>
							<p class="description">
								상담문의 : 1577-3019<br />
								상담시간 : 09:00 ~ 17:30 (점심시간 11:30 ~ 13:00)<br />
								주말 / 공휴일 휴무<br />
							</p>
						</div>
					</div>
					<payment-info></payment-info>
					<site-basic-info></site-basic-info>
					<site-company-info></site-company-info>
					<manager-info></manager-info>
					<order-info></order-info>
					<div class="btn-area">
						<button class="solid large" @click="complete">홈으로</button>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import PaymentInfo from '@/components/apply/resultInfo/PaymentInfo.vue';
import OrderInfo from '@/components/apply/resultInfo/OrderInfo.vue';
import SiteBasicInfo from '@/components/apply/resultInfo/SiteBasicInfo.vue';
import ManagerInfo from '@/components/apply/resultInfo/ManagerInfo.vue';
import SiteCompanyInfo from '@/components/apply/resultInfo/SiteCompanyInfo.vue';

export default {
	created() {
		window.scrollTo(0, 0);
	},
	components: {
		PaymentInfo,
		ManagerInfo,
		SiteBasicInfo,
		SiteCompanyInfo,
		OrderInfo,
	},
	methods: {
		complete() {
			this.$store.commit('CLEAR_ALL_APPLY_INFO');
			this.$router.push(`/home`);
		},
	},
	beforeDestroy() {
		this.$store.commit('CLEAR_ALL_APPLY_INFO');
		this.$store.commit('CLEAR_APPLY_SERVICE_INFO');
		this.$store.commit('CELAR_SELECT_CARD_OPTION');
	},
};
</script>

<style>
@import '../../../assets/css/order.css';
</style>
