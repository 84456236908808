<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">개설정보</h3>
		<div class="contents">
			<div class="group column vertical-form condensed">
				<div class="group row">
					<label class="form-label"><span class="txt">회사명(사이트명)</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.siteName }}</span>
					</div>
				</div>
				<div class="group row" v-if="this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.domain">
					<label class="form-label"><span class="txt">메일 도메인</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.domain }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">접속 URL</span></label>
					<div class="form-value">
						<span class="txt"
							>[{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.name }}]
							{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.url }}.daouoffice.com</span
						>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">그룹웨어 관리자 ID</span></label>
					<div class="form-value">
						<span class="txt">
							[{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.name }}]
							{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.masterId }}</span
						>
					</div>
				</div>
				<template v-for="(item, index) in this.$store.getters.getAllCompanyInfo.companySiteInfo.addonItem">
					<div class="group row" :key="`B-${index}`">
						<label class="form-label"><span class="txt">경영지원/경리회계 관리자 ID</span></label>
						<div class="form-value">
							<span class="txt">[{{ item.name }}] {{ item.masterId }}</span>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
