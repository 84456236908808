<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">회사법인정보</h3>
		<div class="contents">
			<div class="group column vertical-form condensed">
				<div class="group row">
					<label class="form-label"><span class="txt">상호명</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getOrderTempCompanyInfo.corporateName }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">사업자등록번호</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getOrderTempCompanyInfo.businessLicenseNum }}</span>
					</div>
				</div>
				<div class="group row" v-if="this.$store.getters.getOrderTempCompanyInfo.ceoName">
					<label class="form-label"><span class="txt">대표자명</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getOrderTempCompanyInfo.ceoName }}</span>
					</div>
				</div>
				<div class="group row" v-if="this.$store.getters.getOrderTempCompanyInfo.companyAddress">
					<label class="form-label"><span class="txt">주소</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getOrderTempCompanyInfo.companyAddress }}</span>
					</div>
				</div>
				<div class="group row" v-if="this.$store.getters.getOrderTempCompanyInfo.businessCondition">
					<label class="form-label"><span class="txt">업태</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getOrderTempCompanyInfo.businessCondition }}</span>
					</div>
				</div>
				<div class="group row" v-if="this.$store.getters.getOrderTempCompanyInfo.businessType">
					<label class="form-label"><span class="txt">종목</span></label>
					<div class="form-value">
						<span class="txt">{{ this.$store.getters.getOrderTempCompanyInfo.businessType }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SiteCompanyInfo',
};
</script>

<style scoped></style>
