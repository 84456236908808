<template>
	<div v-if="payMethod.payType == 'DEPOSIT' && totalPrice != 0" class="section">
		<h3 class="heading-3-b">무통장 입금안내</h3>
		<!--@3_신용카드-->
		<div class="contents">
			<div class="group column vertical-form condensed">
				<div class="group row">
					<label class="form-label"><span class="txt">입금 은행</span></label>
					<div class="form-value">
						<span class="txt">신한은행 (100-028-394118)</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label"><span class="txt">예금주</span></label>
					<div class="form-value">
						<span class="txt">(주)다우기술</span>
						<p class="description">
							(주) 다우기술에서 입금확인을 한 후부터 정상적으로 사용하실 수 있습니다.<br />
							세금계산서는 결제월 말일에 발행됩니다.<br />
							기타문의는 1577-3019로 문의바랍니다.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		var payMethod = this.$store.getters.getPayMethodInfo;
		const totalPrice = this.$store.getters.getSummuryPrices.totPayPrice;
		return {
			payMethod,
			totalPrice,
		};
	},
};
</script>

<style></style>
